import React from "react";
import { useDispatch } from "react-redux";
// import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { BsArrowRightCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toggleShowToolsSidebar } from "../../../store/utilsDataSlice/utilsDataSlice";

const ToolsTabSingle = ({
  name,
  content_question,
  content_answer,
  tool_type,
  src,
  alt,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleClick = () => {
    history(`/tools/${tool_type}-upload`);
    dispatch(toggleShowToolsSidebar(true));
  };

  return (
    <section className="st_dashboard_tools_card" onClick={handleClick}>
      <div className="st_dashboard_tool_name_main">
        <div className="st_dashboard_tool_name">
          <div className="st_dashboard_tool_logo">
            <img className="st_dashboard_tool_img" src={src} alt={alt} />
          </div>
        </div>
        <div className="st_dashboard_tool_arrow">
          <BsArrowRightCircle color="var(--white)" size={30} />
          {/* <img
            className="ai_tools_icon_img"
            src={process.env.PUBLIC_URL + "/arrow_circle_right.svg"}
            alt="arrow_right"
          /> */}
        </div>
      </div>
      <div className="st_dashboard_tool_content_main">
        <div className="st_dashboard_tool_content">
          <span>{content_question}</span>
          <span>{content_answer}</span>
        </div>
      </div>
    </section>
  );
};

export default ToolsTabSingle;
