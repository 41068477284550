import React, { useRef, useState } from "react";
import { useSpring } from "react-spring";
import styles from "./Card.module.scss";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { setDeltaXPosition } from "../../../../store/loginHomeDataSlice/loginHomeDataSlice";

const Card = ({ subservice }) => {
  const [show, setShown] = useState(false);
  const dispatch = useDispatch();
  const cardRef = useRef();
  const dragStopValue = useRef(0);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });

  return (
    <Draggable
      axis="x"
      position={{ y: 0 }}
      onDrag={(e, { deltaX }) => {
        dragStopValue.current += deltaX;
      }}
      onStop={() => {
        dispatch(setDeltaXPosition(dragStopValue.current));
        setTimeout(() => {
          dragStopValue.current = 0;
          dispatch(setDeltaXPosition(0));
        }, 0);
      }}
      nodeRef={cardRef}
    >
      <div
        className={styles.card}
        style={props3}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
        ref={cardRef}
      >
        <div className={styles.card_heading_wrapper}>
          <div className={styles.card_heading_text}>
            {subservice?.serviceName?.name}
            <div className={styles.service_icons_wrapper}>
              <img src={subservice?.subServiceIconUrl} alt="service icon" />
            </div>
            <div className={styles.heading_bar}></div>
          </div>
        </div>
        <div className={styles.serivce_icons_container_wrapper}>
          <div className={styles.serivce_icons_container}>
            {subservice?.nested_services.map((nestedService, i) => {
              return (
                <div className={styles.serivce_icons_wrapper} key={i}>
                  <img
                    className={styles.service_icons}
                    src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${nestedService?.src}.svg`}
                    alt={nestedService?.name}
                  />
                  <span>{nestedService?.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Card;
