import React, { useEffect, useRef } from "react";
import "./ToolUploadInput.scss";
import "../FileUpload.scss";
import fileSizeChecker from "../../NewRequest/CustomFileInput/fileSizeChecker";
import fileCollectiveSizeChecker from "../../NewRequest/CustomFileInput/fileCollectiveSizeChecker";
import fileNameLimiter from "../../../../utils/fileNameLimiter";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
// import WSLoader from "../../../WSLoader/WSLoader";
const ToolUploadInput = ({
  file_text,
  tagId,
  handleChange,
  styleClass,
  placeholder,
  clear = false,
  active_tool_type,
  maxFileSize,
  maxFileSizeErrFn,
  maxCollectiveFileSize,
  maxCollectiveFileSizeFnErr,
  innerRef,
  isDisabled,
  accept,
  files,
  setFiles,
}) => {
  const hiddenFileInput = useRef(innerRef || null);
  const acceptList = //for rejecting through upload button
    active_tool_type === "flip"
      ? ["pptx", "docx"]
      : active_tool_type === "read"
      ? ["pdf"]
      : active_tool_type === "translate"
      ? ["docx", "xlsx", "pptx"]
      : // : active_tool_type === "consult"
        // ? ".ppt"
        [""];

  const generateAcceptedFormats = (active_tool_type) => {
    switch (active_tool_type) {
      case "flip":
        return {
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx", ".DOCX"],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            [".pptx", ".PPTX"],
        };
      case "read":
        return {
          "application/pdf": [".pdf"],
        };
      case "translate":
        return {
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx", ".DOCX"],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            [".pptx", ".PPTX"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
            ".XLSX",
          ],
        };
      default:
        return {};
    }
  };

  const acceptedFormats = generateAcceptedFormats(active_tool_type);

  const handleClick = (e) => {
    e.stopPropagation();
    hiddenFileInput.current.value = null; // to reset the uploaded files
    hiddenFileInput.current.click();
  };
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      if (
        rejectedFiles.some(
          (file) => file.errors?.[0]?.code === "file-invalid-type",
        )
      ) {
        toast.error("Unsupported file type", { id: "unsupported-tool-file" });
      } else {
        toast.error("Error uploading file", { id: "upload-error" });
      }
      return;
    }

    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > maxFileSize * 1024 * 1024,
    );
    if (oversizedFiles.length > 0) {
      toast.error("File size exceeds the maximum allowed size of 200 MB", {
        id: "file-size-error",
      });
      return;
    }

    setFiles(acceptedFiles); //starts the uplaod process for tool type
    handleChange(acceptedFiles);
  };

  const handleFileChange = (e) => {
    const ext = e.target.value.match(/\.([^.]+)$/)[1]; //to get extensions
    let fileTypeCheck = false;

    acceptList.forEach((elem) => {
      if (elem === ext) {
        fileTypeCheck = true;
      }
    });

    if (!fileTypeCheck) {
      toast.error("file type is unsupported");
      return;
    }
    let clearValdation = true;
    if (maxFileSize) {
      if (
        !fileSizeChecker({
          fileArr: Array.from(e.target?.files),
          maxSizeInMB: maxFileSize,
        })
      ) {
        clearValdation = false;
        toast.error("File size exceeds the maximum allowed size of 200 MB", {
          id: "file-size-error",
        });
        maxFileSizeErrFn();
      }
    }
    if (maxCollectiveFileSize) {
      if (
        !fileCollectiveSizeChecker({
          fileArr: Array.from(e.target?.files),
          maxCollectiveSizeInMB: maxCollectiveFileSize,
        })
      ) {
        clearValdation = false;
        toast.error("File size exceeds the maximum allowed size of 200 MB", {
          id: "file-size-error",
        });

        maxCollectiveFileSizeFnErr();
      }
    }
    if (!clearValdation) return;
    handleChange(Array.from(e.target?.files));
    setFiles(Array.from(e.target?.files));
  };
  useEffect(() => {
    if (clear) {
      hiddenFileInput.current.value = null;
      setFiles(null);
    }
  }, [clear]);

  return (
    <>
      {!isDisabled ? (
        <Dropzone onDrop={handleDrop} accept={acceptedFormats}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="file_upload_middle_container">
              <input
                multiple={false}
                disabled={isDisabled}
                {...getInputProps()}
              />
              <>
                <div className="flip_upload_upload_box">
                  <span className="upload_file_name_text">
                    {files && files.length > 0
                      ? fileNameLimiter(files[0].name)
                      : ""}
                  </span>
                  <div>
                    <img
                      className="cloud_upload_img"
                      src={process.env.PUBLIC_URL + "/cloud_upload.svg"}
                      alt="upload"
                    />
                  </div>
                </div>

                <div className="flip_upload_size"></div>
                <div className="flip_ppt_text">{file_text}</div>
                <div className="flip_or_section">
                  <span className="or_section_underline">
                    <hr />
                  </span>
                  <span className="or_text">OR</span>
                  <span className="or_section_underline">
                    <hr />
                  </span>
                </div>
                <div className="flip_upload_btn">
                  <div
                    className={`tool_media_input_wrapper ${styleClass}`}
                    onClick={handleClick}
                  >
                    <input
                      disabled={isDisabled}
                      style={{ display: "none" }}
                      type="file"
                      id="tool_media_input_input_file"
                      data-tag-id={tagId}
                      name="tool_media_input_input_file"
                      ref={hiddenFileInput}
                      multiple={false}
                      accept={accept}
                      onChange={handleFileChange}
                    />
                    <span className="tool_media_input_input_btn">
                      Upload File
                    </span>
                  </div>
                </div>
              </>
            </div>
          )}
        </Dropzone>
      ) : (
        <div className="disabled_container_flip">
          <div className="stc_upload_loader">
            <div className="spin_upload_loader"></div>
          </div>
          <span>Uploading File...</span>
        </div>
      )}
    </>
  );
};

export default ToolUploadInput;
