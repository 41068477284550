const fileNameLimiter = (fileName) => {
  const maxLength = 26;

  if (fileName.length > maxLength) {
    const extensionIndex = fileName.lastIndexOf(".");
    const name = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex + 1);
    const limitedName = name.substring(0, maxLength - (extension.length + 4));
    const limitedData = limitedName + "...." + extension;
    return limitedData;
  } else {
    return fileName;
  }
};

export default fileNameLimiter;
