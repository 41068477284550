import React, { useEffect, useRef, useState } from "react";
import { lazy_load_image } from "../../../../..";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileDownloadLink,
  sendConsultResponseFeedback,
  setNegativeResponseId,
  toggleShowConsultFilePane,
  // translateTextMessage,
  // updateFileDownloadLink,
  updatePreviewReferenceData,
} from "../../../../../store/consultgptDataSlice/consultgptDataSlice";
import {
  CHAT_MESSAGE_TYPE,
  MESSAGE_FEEDBACK_TYPE,
} from "../../../../../store/consultgptDataSlice/common";
import TOOLS_MODALS from "../../../../ToolsConstants/TOOLS_MODALS";
import { setModal } from "../../../../../store/utilsDataSlice/utilsDataSlice";
import fileNameLimiter from "../../../../../utils/fileNameLimiter";
import ThumbsUp from "../../../../../Icons/ToolsConsultIcons/ThumbsUp";
import ThumbsDown from "../../../../../Icons/ToolsConsultIcons/ThumbsDown";
import DownloadPopover from "./DownloadPopover/DownloadPopover";

const OutputReference = ({ content, dispatch, file_preview }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [sourcePreviews, setSourcePreviews] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);

  const hanleOpenFilePreview = (data, content, index) => {
    dispatch(toggleShowConsultFilePane(true));
    dispatch(getFileDownloadLink({ file_id: data?.file_identifier }));
    dispatch(
      updatePreviewReferenceData({
        reference_id: data?.file_identifier,
        reference_page: data?.page_number,
        reference_text: data?.source_text,
        message_id: content?.uuid,
        reference_index: index,
        reference_file_name: data?.file_name,
      }),
    );
  };

  const isActiveReference = (data, content, index) => {
    if (!data) return false;
    const isActive =
      file_preview.reference_file_id === data.file_identifier &&
      file_preview.reference_page === data.page_number &&
      file_preview.reference_msg_id === content.uuid &&
      file_preview.reference_index === index;
    return isActive;
  };

  useEffect(() => {
    setSourcePreviews(
      content?.output_references.slice(0, getNumberOfPreviewsToShow()),
    );
  }, []);

  const getNumberOfPreviewsToShow = () => {
    if (window.innerWidth <= 500) return 1;
    else if (window.innerWidth <= 700) return 2;
    else return 3;
  };

  const handleSeeMoreBtn = () => {
    if (seeMore) setSourcePreviews(content?.output_references);
    else
      setSourcePreviews(
        content?.output_references.slice(0, getNumberOfPreviewsToShow()),
      );

    setSeeMore(!seeMore);
  };
  const previewsToShowInitially = getNumberOfPreviewsToShow();
  const hasMorePreviews =
    content?.output_references.length > previewsToShowInitially;

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdownIndex(null);
    }
  };

  useEffect(() => {
    if (activeDropdownIndex !== null) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeDropdownIndex]);

  return (
    <div className="tools_consult_sender_reference_source_file_previews_wrapper">
      {sourcePreviews.map((data, index) => (
        <div
          className={`${
            isActiveReference(data, content, index)
              ? "tools_consult_sender_active_reference_content_wrapper"
              : ""
          } tools_consult_sender_reference_file_preview`}
          onClick={() => hanleOpenFilePreview(data, content, index)}
          key={index}
        >
          <DownloadPopover
            index={index}
            file_id={data?.file_identifier}
            activeDropdownIndex={activeDropdownIndex}
            setActiveDropdownIndex={setActiveDropdownIndex}
          />

          <img
            className="file_iframe"
            src={data?.preview_page_link}
            alt="pdf"
          />
          <p className="tools_consult_file_reference_text">
            {fileNameLimiter(data?.file_name)}
          </p>
        </div>
      ))}
      {hasMorePreviews && (
        <button
          className="tools_consult_sender_reference_file_preview_see_more_btn"
          onClick={handleSeeMoreBtn}
        >
          {seeMore ? "See More" : "See Less"}
        </button>
      )}
    </div>
  );
};

const ResponeFeedback = ({ content, dispatch, chat_data }) => {
  const handleSendPositiveFeedback = (feedback) => {
    dispatch(
      sendConsultResponseFeedback({
        chat_id: chat_data.root_node_id,
        message_id: content?.uuid,
        feedback: feedback,
      }),
    );
  };

  const handleSendNegativeFeedback = (feedback, id) => {
    dispatch(
      sendConsultResponseFeedback({
        chat_id: chat_data.root_node_id,
        message_id: content?.uuid,
        feedback: feedback,
      }),
    );
    if (feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE) {
      dispatch(setNegativeResponseId(id));
      dispatch(setModal(TOOLS_MODALS.CONSULT_RESPONSE_EDIT));
    }
  };

  return (
    <div className="tools_consult_helpful_texts">
      <span>Was this helpful?</span>
      <div className="tools_consult_feedback_btn_wrapper">
        <button
          className={` ${
            content?.feedback === MESSAGE_FEEDBACK_TYPE.POSITIVE
              ? "tool_consult_feedback_thumb_selected"
              : "tool_consult_feedback_thumb_btn"
          }`}
          onClick={() =>
            handleSendPositiveFeedback(
              content?.feedback === MESSAGE_FEEDBACK_TYPE.POSITIVE
                ? MESSAGE_FEEDBACK_TYPE.NEUTRAL
                : MESSAGE_FEEDBACK_TYPE.POSITIVE,
            )
          }
        >
          <ThumbsUp />
        </button>

        <button
          className={` ${
            content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE
              ? "tool_consult_feedback_thumb_selected"
              : "tool_consult_feedback_thumb_btn"
          }`}
          onClick={() =>
            handleSendNegativeFeedback(
              content?.feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE
                ? MESSAGE_FEEDBACK_TYPE.NEUTRAL
                : MESSAGE_FEEDBACK_TYPE.NEGATIVE,
              content?.uuid,
            )
          }
        >
          <ThumbsDown />
        </button>
      </div>
    </div>
  );
};

const BotMessage = ({ content }) => {
  // const [showTranslated, setShowTranslated] = useState(false);
  const { chat_data, file_preview } = useSelector(
    (state) => state.consultGptData,
  );
  const dispatch = useDispatch();

  // const handleGetTranslation = (content) => {
  //   if (showTranslated) {
  //     setShowTranslated(false);
  //   } else {
  //     if (content?.translated_content && content.translated_content !== "") {
  //       setShowTranslated(true);
  //     } else {
  //       dispatch(
  //         translateTextMessage({
  //           chat_id: chat_data.root_node_id,
  //           message_id: content?.uuid,
  //         }),
  //       )
  //         .then(() => {
  //           setShowTranslated(true);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }
  //   }
  // };

  const isGreetingMessage =
    chat_data.currentChatFlow.find(
      (messageId) =>
        chat_data.messages[messageId]?.type === CHAT_MESSAGE_TYPE.BOT,
    ) === content?.uuid;

  return (
    <div className="tools_consult_sender_message_wrapper" key={content?.uuid}>
      <div className="tools_consult_image_wrapper">
        <img
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/consult_profile_img.svg"}
          alt="consult.ai"
          className="tools_consult_image"
        />
      </div>
      <div className="tools_consult_sender_message_outer_container">
        <div className="tools_consult_sender_message_container">
          <div className="tools_consult_sender_name_text_wrapper">
            <span>Consult.ai</span>
          </div>
          <div className="tools_consult_sender_text_message_wrapper">
            <div className="tools_consult_sender_text_message">
              {/* <div
                dir={showTranslated ? "rtl" : "ltr"}
                dangerouslySetInnerHTML={{
                  __html: showTranslated
                    ? content?.translated_content
                    : content?.content,
                }}
              ></div> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: content?.content,
                }}
              ></div>
            </div>
            {/* <button
              className="tools_consult_text_translate"
              onClick={() => handleGetTranslation(content)}
            >
              <img
                onLoad={lazy_load_image}
                src={process.env.PUBLIC_URL + "/en_to_ar_translate_icon.svg"}
                alt="consult.ai"
                className="tools_consult_translate_image"
              />
            </button> */}
          </div>
          {content?.output_references && (
            <OutputReference
              content={content}
              dispatch={dispatch}
              file_preview={file_preview}
            />
          )}
        </div>
        {!isGreetingMessage && (
          <ResponeFeedback
            content={content}
            dispatch={dispatch}
            chat_data={chat_data}
          />
        )}
      </div>
    </div>
  );
};

export default BotMessage;
