import React, { useState } from "react";
import styles from "./CommercialModals.module.scss";
import { commercialModalsData } from "./CommercialModalsData";
import Gradient from "../../../public_icons/HomePage/Icons/Gradient";
import VGNonHoveredTick from "../../../public_icons/Icons/VGNonHoveredTick";
import { useDispatch } from "react-redux";
import {
  setModalShow,
  setModalTitle,
  setServiceName,
} from "../../../store/loginHomeDataSlice/loginHomeDataSlice";
import { MODAL_TYPES } from "../../../store/loginHomeDataSlice/common";

const CommercialModals = () => {
  const dispatch = useDispatch();
  const [activeSlider, setActiveSlider] = useState(commercialModalsData[0]);
  const SelectedServiceName = activeSlider?.heading_text;

  function handleSendBrief({ SelectedServiceName }) {
    dispatch(setServiceName(SelectedServiceName));
    dispatch(setModalTitle("Send a brief"));
    dispatch(setModalShow(MODAL_TYPES.GET_A_QUOTE));
  }

  return (
    <div className={styles.commercial_modals_outer_container}>
      <div className={styles.top_gradient}>
        <Gradient />
      </div>
      <div className={styles.commercial_modals_heading_wrapper}>
        <h3 className={styles.commercial_modals_heading_text}>
          Client-Centric Commercial Solutions
        </h3>
        <h4 className={styles.commercial_modals_sub_heading_text}>
          Your Strategy, Your Choice
        </h4>
      </div>
      <div className={styles.commercial_modals_expansion_carousel}>
        {commercialModalsData.map((info) => {
          return (
            <div
              className={`${styles.entity_slider_wrapper} ${
                activeSlider.id === info.id
                  ? styles.active_slider
                  : styles.unactive_slider
              }`}
              key={info.id}
              onClick={() => {
                setActiveSlider(info);
              }}
            >
              <div className={`${styles.entity_slider} `}>
                <div
                  className={`${styles.entity_slider_header} ${
                    activeSlider.id === info.id
                      ? styles.active_slider_header
                      : styles.unactive_slider_header
                  }`}
                >
                  <div
                    className={`${styles.entity_slider_heading_wrapper} ${
                      activeSlider.id === info.id
                        ? styles.active_entity_slider_heading_wrapper
                        : styles.unactive_entity_slider_heading_wrapper
                    }`}
                  >
                    <h4
                      className={`${styles.entity_slider_heading_text} ${
                        activeSlider.id === info.id
                          ? styles.active_slider_heading_text
                          : styles.unactive_slider_heading_text
                      }`}
                    >
                      {info?.heading_text}
                    </h4>
                    <p
                      className={`${styles.model_desc} ${
                        activeSlider.id === info.id
                          ? styles.active_model_desc
                          : styles.unactive_model_desc
                      }`}
                    >
                      {info?.model_desc}
                    </p>
                  </div>
                  {activeSlider.id !== info.id && (
                    <div className={styles.animated_under_line}></div>
                  )}
                  <div
                    className={`${
                      styles.entity_slider_service_banner_wrapper
                    } ${
                      activeSlider.id === info.id
                        ? styles.active_banner_wrapper
                        : styles.unactive_banner_wrapper
                    }`}
                  >
                    <div className={styles.entity_slider_service_banner}>
                      <p className={styles.entity_slider_service_banner_head}>
                        Recommended for
                      </p>
                      <p className={styles.banner_highlight}>
                        {info?.banner_text}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    activeSlider.id === info.id
                      ? styles.entity_modal_body_display
                      : styles.entity_modal_body_hidden
                  }`}
                >
                  <img
                    src={info.image_src}
                    alt={info.heading_text}
                    className={styles.entity_slider_image}
                  />
                  <div className={styles.entity_service_desc_wrapper}>
                    {info?.featured_text.map((serviceDeatils, i) => {
                      return (
                        <div
                          className={styles.entity_service_desc_list}
                          key={i}
                        >
                          <VGNonHoveredTick />
                          <p className={styles.service_desc_text}>
                            {serviceDeatils.text}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.entity_cta_btn_wrapper}>
                    <p className={styles.entity_credits_claim}>
                      {info?.claim_credits}
                    </p>
                    <button
                      className={styles.entity_cta_btn}
                      onClick={() => handleSendBrief({ SelectedServiceName })}
                    >
                      {info?.cta_btn_text}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CommercialModals;
