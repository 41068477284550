export const FILE_UPLOAD_STATE = {
  UPLOADED: "uploaded",
  PROCESSING: "processing",
  UPLOADING: "uploading",
  FAILED: "failed",
  NONE: null,
};

export const CHAT_ERROR_MESSAGE_TYPE = {
  PARENT_NOT_FOUND: "PARENT_NOT_FOUND",
  SAME_CURRENT_CHILD_ID: "SAME_CURRENT_CHILD_ID",
  INVALID_CURRENT_ID: "INVALID_CURRENT_ID",
  INVALID_CHILD_ID: "INVALID_CHILD_ID",
  QUERY_LIMIT_EXCEED: "QUERY_LIMIT_EXCEED",
  QUERY_CHAR_LIMIT_EXCEED: "QUERY_CHAR_LIMIT_EXCEED",
  CURRENT_CHAT_NOT_FOUND: "CURRENT_CHAT_NOT_FOUND",
  CHAT_NOT_FOUND: "CHAT_NOT_FOUND",
  AI_PROCESSING_FAIL: "AI_PROCESSING_FAIL",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  INVALID_PARENT: "INVALID_PARENT",
  NONE: null,
};

export const CHAT_MESSAGE_TYPE = {
  BOT: "AI",
  USER: "HUMAN",
  NOTIFICATION: "NOTIFICATION",
  ERROR: "ERROR",
  NONE: null,
};

export const CHAT_NOTIFICATION_TYPE = {
  FILE_PROCESSED: "file_process",
  FILE_INCLUDED: "file_include",
  NONE: null,
};

export const FILE_UPLOAD_DASHBOARD = {
  UPLOAD: "upload_file",
  INCLUDE: "include_file",
};

export const CONSULT_FILE_PANE = {
  PREVIEW: "Preview",
  UPLOAD: "Upload File",
};

export const BOT_RESPONSE_FEEDBACK = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
};

export const CHAT_TYPE = {
  TALK_TO_CONSULT: "query_ez",
  FILE_UPLOAD: "query_upload",
};

export const MESSAGE_FEEDBACK_TYPE = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEUTRAL: "neutral",
};

export const INITIAL_MSG_TEXT = {
  TALK_TO_CONSULT:
    "Hello there! I have read through all data relevant to the Middle East. Ask me your business questions and I will not just point you to the relevant document but also summarize the arabic content for you in English!",
  FILE_UPLOAD:
    "Hello there! I have read through all data relevant to your files. Ask me your business questions and I will not just point you to the relevant information but also summarize the arabic content for you in English!",
};

export const INITIAL_ARABIC_MSG_TEXT = {
  TALK_TO_CONSULT:
    "أهلاً بك! لقد قرأت جميع البيانات ذات الصلة بالشرق الأوسط. اطرح علي أسئلة الأعمال الخاصة بك ولن أقوم بتوجيهك إلى المستند ذي الصلة فحسب، بل سأقوم أيضًا بتلخيص المحتوى العربي لك باللغة الإنجليزية!",
  FILE_UPLOAD:
    "أهلاً بك! لقد قرأت جميع البيانات ذات الصلة بملفاتك. اطرح علي أسئلة عملك ولن أشير فقط إلى المعلومات ذات الصلة ولكن سأقوم أيضًا بتلخيص المحتوى العربي لك باللغة الإنجليزية!",
};

export const getLastParentId = (currentFlow, messages) => {
  let parentMessageId = null;
  for (let i = currentFlow.length - 1; i >= 0; i--) {
    const messageId = currentFlow[i];
    const message = messages[messageId];
    if (message.type === CHAT_MESSAGE_TYPE.BOT) {
      parentMessageId = messageId;
      break;
    }
  }
  // If no BOT message is found, return the last message in the current flow
  if (!parentMessageId && currentFlow.length > 0) {
    parentMessageId = currentFlow[currentFlow.length - 1];
  }
  return parentMessageId;
};

export const CHAT_QUERY_LIMIT = 10;

const initialState = {
  // socket state
  loading: false,
  error: null,
  socket: null,

  // notification socket
  notificationSocket: {
    loading: false,
    error: null,
    socket: null,
  },
  // chat state
  chat_data: {
    loading: false,
    error: null,
    root_node_id: null,
    messages: null,
    errorMessageData: [],
    currentChatFlow: [],
    chat_type: null,
    hasInitialMessage: false,
    query_count: null,
    is_chat_input_disabled: false,
    is_initial_flow_set: false,
    initial_file_status_check: false,
  },
  latest_message_id: null,
  // chat creation state
  chatCreation: {
    loading: false,
    error: null,
    isNewChat: false,
    initialMessageSend: false,
  },

  // text translate state
  textTranslation: {
    loading: false,
    error: false,
    success: false,
  },

  // chat message edit states
  messageEditMode: {},
  userMessageEdit: {
    editMessageId: null,
    editMessageParentId: null,
    isMessageEditMode: false,
    negativeResponseMessageId: null,
    editMessageText: null,
  },
  active_file_upload_dashboard: FILE_UPLOAD_DASHBOARD.UPLOAD,
  active_file_tab: CONSULT_FILE_PANE.UPLOAD,

  //include file state
  included_file_data: {
    data: null,
    IncludedFile: [],
    loading: false,
    error: null,

    include_send_file: {
      loading: false,
      error: null,
    },
  },
  consult_file_pane_sidebar: false,

  //file preview State
  file_preview: {
    reference_file_id: null,
    file_preview_url: null,
    is_file_preview_enabled: false,
    reference_text: [],
    reference_page: null,
    reference_msg_id: null,
    reference_index: null,
    reference_file_name: null,
    loading: false,
    error: null,
  },
};

export default initialState;
