const TOOLS_MODALS = {
  NEW_REQ: "new-req",
  WALLET: "wallet",
  YOUTUBE: "youtube",
  SHARE_MODAL: "share-modal",
  SHARE_WARNING: "share-warning",
  SHARE_REVOKE: "share-revoke",
  REVOKE_REQUEST_ERROR: "revoke-request-error",
  DECLINE_REQUEST: "decline-request",
  REVOKE_REQUEST: "revoke-request",
  CONSULT_RESPONSE_EDIT: "consult-response-edit",
  CHAT_QUERY_LIMIT: "chat-query-limit",
  NONE: null,

  LOADER: "loader",
};

export default TOOLS_MODALS;
