import React from "react";

import { Popover } from "react-tiny-popover";
import { useDispatch } from "react-redux";

import { updateFileDownloadLink } from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";

const DownloadPopover = ({
  index,
  file_id,
  activeDropdownIndex,
  setActiveDropdownIndex,
}) => {
  const dispatch = useDispatch();

  const isVerySmallScreen = window?.matchMedia("(max-width: 350px)").matches;

  const fileDropdownStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    backgroundColor: "var(--white)",
    padding: "0.5rem",
    // height: 5.3rem;
    width: isVerySmallScreen ? "8.5rem" : "9.5rem",
    borderRadius: "4px",
    color: "var(--dark-blue)",
    listStyle: "none",
    fontSize: isVerySmallScreen ? "var(--rem-sm-font)" : "var(--rem-base-font)",
    maxHeight: "5.3rem",
    cursor: "pointer",
    marginRight: "1rem",
  };

  const calculateTransformPosition = (position) => {
    // console.log(position.position);
    switch (position?.position) {
      case "right":
        return { top: 20, left: 13 };
      case "left":
        return { top: 20, left: -8 };
      case "top":
        return { top: -10 };
      case "bottom":
        return { top: 10 };
    }
  };

  const handleUpdateDownloadLink = async (e) => {
    e.stopPropagation();
    dispatch(updateFileDownloadLink({ file_id }));
    setActiveDropdownIndex(null);
    // setIsPopoverOpen(false);
  };

  return (
    <Popover
      isOpen={index === activeDropdownIndex}
      containerStyle={{ zIndex: 1 }}
      content={
        <div style={fileDropdownStyles}>
          <li onClick={(e) => handleUpdateDownloadLink(e)}>Download PDF</li>
          <li onClick={(e) => handleUpdateDownloadLink(e)}>
            Download Original
          </li>
          {/* <li>Translate File</li> */}
        </div>
      }
      positions={["right", "top", "left", "bottom"]}
      onClickOutside={() => setActiveDropdownIndex(null)}
      transform={(position) => calculateTransformPosition(position)}
      transformMode="relative"
      // align="start"
      // ref={}
    >
      <img
        src={
          process.env.PUBLIC_URL +
          "/resources/consult_file_status_icon/download_options.svg"
        }
        alt="icon"
        onClick={(e) => {
          e.stopPropagation();
          setActiveDropdownIndex(index === activeDropdownIndex ? null : index);
          //   setIsPopoverOpen(!isPopoverOpen);
        }}
      />
    </Popover>
  );
};

export default DownloadPopover;
