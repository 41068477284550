import React, { useEffect, useState } from "react";
import style from "./NavbarRouterBtns.module.scss";
import { MdClose, MdOutlineMenu } from "react-icons/md";
import ServiceDropLists from "../../ServicesDropdownSupport/ServiceDropLists";

const NavbarRouterBtns = ({
  capabilityIconRef,
  isCapabilitydropdownActive,
  capabilityDropdownRef,
  mobilecapabilityuIconRef,
  setIsCapabilitydropdownActive,
}) => {
  const [navMenubar, setNavMenubar] = useState(false);

  const onMobileGridMenuHandler = () => {
    setIsCapabilitydropdownActive((prev) => !prev);
    setNavMenubar(false);
  };
  const onNavMenubarHandler = () => {
    setNavMenubar((prev) => !prev);
  };

  const navbarScrollTop = () => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop < 10) {
      setNavMenubar(false);
    }
  };
  const onGridMenuHandler = () => {
    setIsCapabilitydropdownActive((prev) => !prev);
  };

  const handleWindowScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", navbarScrollTop);
    return () => window.removeEventListener("scroll", navbarScrollTop);
  }, [navMenubar]);

  return (
    <>
      <div
        className={`${style.nav_menubaricons_container}`}
        onClick={onNavMenubarHandler}
      >
        <span className={style.nav_menusicons_wrapper}>
          {navMenubar ? <MdClose /> : <MdOutlineMenu />}
        </span>
      </div>
      <div className={style.navbar_allIcons_Container}>
        <div
          className={`${style.navbar_secureTranserIcon_Container}`}
          onClick={onGridMenuHandler}
        >
          <span ref={capabilityIconRef}>
            {isCapabilitydropdownActive ? (
              <img
                src="/resources/LoginIcons/70+Serviceslogo.svg"
                className={style.navmenu_icons70fontsize}
              />
            ) : (
              <img
                src="/resources/LoginIcons/70+Servicesbg.svg"
                className={style.navmenu_icons70fontsize}
              />
            )}
          </span>
        </div>
        <div className={`${style.navbar_secureTranserIcon_Container}`}>
          <a href={`${process.env.REACT_APP_AI_TOOLS}`} aria-label="AI-TOOLS">
            <img
              src="/resources/LoginIcons/ai_light.svg"
              className={style.navmenu_iconsfontsize}
            />
          </a>
        </div>
        <div
          className={`${style.navbar_secureTranserIcon_Container}`}
          onClick={handleWindowScrollToTop}
        >
          <img
            src="/resources/LoginIcons/io_light.svg"
            className={style.navmenu_iconsfontsize}
          />
        </div>
      </div>
      <div
        className={`${style.navmenubar_dropdown_Container} ${
          navMenubar ? "" : style.navmenubar_dropdown_hide_Container
        }`}
      >
        <div className={`${style.nav_mobiledeviceIcons_Container}`}>
          <div
            className={`${style.navmenu_allIcons_wrapper} ${
              navMenubar ? "visible" : "invisible"
            }`}
            onClick={onMobileGridMenuHandler}
          >
            <div
              className={`${style.navbar_secureTranserIcon_Container}`}
              ref={mobilecapabilityuIconRef}
            >
              <img
                src="/resources/LoginIcons/70+Servicesbg.svg"
                className={style.navmenu_icons70fontsize}
              />
            </div>
            <span>
              <img src="/resources/LoginIcons/Icon-awesome-angle-down.svg" />
            </span>
          </div>
          <a
            href={`${process.env.REACT_APP_AI_TOOLS}`}
            className={style.navmenu_allIcons_outer_wrapper}
          >
            <div
              className={`${style.navmenu_allIcons_wrapper}  ${
                navMenubar ? "visible" : "invisible"
              }`}
            >
              <div className={`${style.navbar_secureTranserIcon_Container}`}>
                <img
                  src="/resources/LoginIcons/ai_light.svg"
                  className={style.navmenu_iconsfontsize}
                />
              </div>
              <span onClick={() => setNavMenubar(false)}>
                <a aria-label="AI Tools">
                  <img src="/resources/LoginIcons/Icon-awesome-angle-down.svg" />
                </a>
              </span>
            </div>
          </a>

          <div
            className={`${style.navmenu_allIcons_wrapper}  ${
              navMenubar ? "visible" : "invisible"
            }`}
            onClick={handleWindowScrollToTop}
          >
            <div className={`${style.navbar_secureTranserIcon_Container}`}>
              <img
                src="/resources/LoginIcons/io_light.svg"
                className={style.navmenu_iconsfontsize}
              />
            </div>
            <span onClick={() => setNavMenubar(false)}>
              <a aria-label="Secure Transfer">
                <img src="/resources/LoginIcons/Icon-awesome-angle-down.svg" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <>
        <ServiceDropLists
          setShowIconList={setIsCapabilitydropdownActive}
          showIconList={isCapabilitydropdownActive}
          refElement={capabilityDropdownRef}
        />
      </>
    </>
  );
};

export default NavbarRouterBtns;
