import style from "./Navbar.module.scss";
// import { CgMenuGridR } from "react-icons/cg";
import { useEffect, useRef, useState } from "react";
// import { IoPower } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { getData } from "../../helpers/request";
import { PlaceholderInputData } from "./NavbarData";
import SearchDropdownFileIcon from "../HomePage/SearchDropdownSupport/SearchDropdownFileIcon";
// import ServiceDropLists from "../ServicesDropdownSupport/ServiceDropLists";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setKeyword } from "../../store/loginHomeDataSlice/loginHomeDataSlice";
import NavbarRouterBtns from "./NavbarRouterBtns/NavbarRouterBtns";

export const oldNavRouterBtn = () => {
  return (
    <section className={style.loginNavRedirectBtnsContainer}>
      <a
        className={style.loginNavBtn}
        href="https://ez.works"
        // target="_blank"
        // rel="noreferrer"
      >
        <img
          className={style.loginNavBtnImg}
          src="/resources/LoginIcons/70light.svg"
          alt="70 icons"
        ></img>
        {/* <img
            className={style.loginNavBtnText}
            src="/resources/LoginIcons/servicesLogo.svg"
            alt="services"
          ></img> */}
      </a>
      <a
        className={style.loginNavBtn}
        href="https://ez.works"
        // target="_blank"
        // rel="noreferrer"
      >
        <img
          className={style.loginNavBtnImg}
          src="/resources/LoginIcons/ai_light.svg"
          alt=" ai tools"
        ></img>
        {/* <img
            className={style.loginNavBtnText}
            src="/resources/LoginIcons/toolsLogo.svg"
            alt=" tools logo"
          ></img> */}
      </a>
      <div className={style.loginNavBtn}>
        <img
          className={style.loginNavBtnImg}
          src="/resources/LoginIcons/io_light.svg"
          alt="io logo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        ></img>
        {/* <img
            className={style.loginNavBtnText}
            src="/resources/LoginIcons/newLoginLogo.svg"
            alt="login logo"
          ></img> */}
      </div>
    </section>
  );
};

const Navbar = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const { pageNav, navShow } = useSelector((state) => state.loginHomeData);
  const [searchInputData, setSearchInputData] = useState(null);
  const [inputFieldisActive, setInputFieldIsActive] = useState(false);
  const [searchSuggestionsData, setSearchSuggestionsData] = useState(null);
  // const { setArticleSlug } = useContext(SlugContext);
  // const { setKeyword } = useContext(searchWordContext);
  // const { setSearchPageContentLoading } = useContext(searchWordContext);
  // const { setPopularResults } = useContext(searchWordContext);
  // const { setSimilarResults } = useContext(searchWordContext);
  // const { setJobOpenings } = useContext(searchWordContext);
  const searchKeywordRef = useRef(null);
  const searchThrotling = useRef(false);
  const [isCapabilitydropdownActive, setIsCapabilitydropdownActive] =
    useState(false);
  const capabilityDropdownRef = useState(null);
  const capabilityIconRef = useRef(null);
  const [isHoverable, setIsHoverable] = useState(false); // eslint-disable-line
  const searchDropDownRef = useRef();
  const mobilecapabilityuIconRef = useRef(null);
  const [randomData, setRandomData] = useState([]);
  const [navVisible, setNavVisible] = useState(false);

  useEffect(() => {
    const shuffleArray = (array) => {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    };
    const shuffledData = shuffleArray([...PlaceholderInputData])?.slice(0, 10);
    shuffledData.push(shuffledData[0]);
    setRandomData(shuffledData);
  }, []);

  const SearchListItems = ({ title, slug, articleFormat }) => {
    return (
      <Link to={`${process.env.REACT_APP_WEBSITE_URL}/${slug}`} key={slug}>
        <div className={style.search_suggestionItems_wrapper}>
          <div className={style.search_suggestion_item_nav}>
            <div className={style.Navbar_searchSuggestion_Headingwrapper}>
              <SearchDropdownFileIcon articleFormat={articleFormat} />
              <h5 className={style.Navbar_searchSuggestion_TextHeadings}>
                {title.length > 75 ? title.substring(0, 75) + "..." : title}
              </h5>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const onInputFormHandler = (e) => {
    e.preventDefault();
    const isSearchPage = router.pathname === "/search";
    const loc = JSON.parse(localStorage.getItem("loc"));
    if (searchInputData?.trim().length === 0) {
      setSearchSuggestionsData(null);
      return;
    }
    if (isSearchPage) {
      dispatch(setKeyword(searchInputData));
      // setSearchPageContentLoading(true);
      // setPopularResults(null);
      // setSimilarResults(null);
      // setJobOpenings(null);

      searchThrotling.current = true;
      const ThrotlingId = setTimeout(() => {
        searchThrotling.current = false;
        getData(
          `${
            process.env.REACT_APP_WEBSITE_URL
          }/api/web/v1/search-article/?search=${searchInputData?.replace(
            /[&?=]/g,
            " ",
          )}&country=${loc?.country ? loc.country : ""}&city=${
            loc?.city ? loc.city : ""
          }`,
        )
          .then((res) => {
            if (res.success === true) {
              window.location = `${
                process.env.REACT_APP_WEBSITE_URL
              }/search?q=${searchInputData?.replace(" ", "+")}`;
              // setSearchPageContentLoading(false);
              // setPopularResults(res?.data?.popular_results);
              // setSimilarResults(res?.data?.similar_results);
              // setJobOpenings(res?.data?.job_posts);
              // setSearchInputData("");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 500);
      return () => clearTimeout(ThrotlingId);
    } else {
      dispatch(setKeyword(searchInputData));
      window.location = `${
        process.env.REACT_APP_WEBSITE_URL
      }/search?q=${searchInputData?.replace(" ", "+")}`;
      setSearchInputData("");
    }
  };

  const onSearchBarExtendHandler = (e) => {
    if (inputFieldisActive) {
      onInputFormHandler(e);
    } else {
      setInputFieldIsActive((prev) => !prev);
      setSearchInputData("");
    }
    onInputFormHandler(e);
  };

  // will change it later
  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   const path = url.pathname;
  //   const parts = path.split("/");
  //   const variable = parts[1];
  //   if (variable === "join-us") {
  //     setScrollLength(2000);
  //   } else {
  //     setScrollLength(500);
  //   }
  // }, []);

  // const navbarScrollTop = () => {
  //   const windowScrollTop = window.scrollY;
  //   if (windowScrollTop > 500) {
  //     setNavShow(true);
  //   } else {
  //     setNavShow(false);
  //     setSearchInputData("");
  //     setInputFieldIsActive(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", navbarScrollTop);
  //   return () => window.removeEventListener("scroll", navbarScrollTop);
  // }, [navShow]);

  useEffect(() => {
    const loc = JSON.parse(localStorage.getItem("loc"));
    if (!searchInputData) {
      return;
    }
    if (searchInputData.trim().length === 0) {
      setSearchSuggestionsData(null);
      return;
    }
    searchThrotling.current = true;
    const searchThrotlingId = setTimeout(() => {
      searchThrotling.current = false;
      getData(
        `${
          process.env.REACT_APP_WEBSITE_URL
        }/api/web/v1/search-article/?search=${searchInputData?.replace(
          /[&?=]/g,
          " ",
        )}&country=${loc?.country ? loc.country : ""}&city=${
          loc?.city ? loc.city : ""
        }`,
      )
        .then((res) => {
          if (res?.success === true) {
            setSearchSuggestionsData(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 500);

    return () => clearTimeout(searchThrotlingId);
  }, [searchInputData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        capabilityDropdownRef.current &&
        !capabilityDropdownRef.current.contains(event.target) &&
        capabilityIconRef.current &&
        !capabilityIconRef.current.contains(event.target)
      ) {
        setIsCapabilitydropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [capabilityDropdownRef, capabilityIconRef]);

  const isHoverSupported = () => {
    // Check if the browser supports the 'hover' media feature
    const hoverMediaQuery = window.matchMedia("(hover: hover)");
    // Check if the browser supports the 'any-hover' media feature
    const anyHoverMediaQuery = window.matchMedia("(any-hover: hover)");
    // Return true if either 'hover' or 'any-hover' media feature is supported
    return hoverMediaQuery.matches || anyHoverMediaQuery.matches;
  };

  useEffect(() => {
    // Check if the device supports hover interactions
    const hoverSupported = isHoverSupported();
    // If hover is supported, set the hoverState value to true
    if (hoverSupported === true) {
      setIsHoverable(true);
    } else {
      setIsHoverable(false);
    }
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchKeywordRef.current &&
        !searchKeywordRef.current.contains(event.target) &&
        (!searchDropDownRef.current ||
          !searchDropDownRef.current.contains(event.target))
      ) {
        setSearchInputData("");
        setInputFieldIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [searchKeywordRef]);

  // const showNav = () => {
  //   if (!pageNav) {
  //     if (window.scrollY >= 500 || navShow) {
  //       setNavVisible(true);
  //     } else {
  //       setNavVisible(false);
  //       setSearchInputData("");
  //       setInputFieldIsActive(false);
  //     }
  //   } else {
  //     if (navShow) {
  //       setNavVisible(true);
  //     } else {
  //       if (window.scrollY >= 500) {
  //         setNavVisible(true);
  //       } else {
  //         setNavVisible(false);
  //         setSearchInputData("");
  //         setInputFieldIsActive(false);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (!pageNav && window.scrollY < 500) {
        setNavVisible(false);
        setSearchInputData("");
        setInputFieldIsActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pageNav]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 500 || navShow) {
        setNavVisible(true);
      } else {
        setNavVisible(false);
        setSearchInputData("");
        setInputFieldIsActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navShow]);

  return (
    <nav
      className={`${style.navbar_Container} ${
        navVisible ? style.show_nav : style.hidden_nav
      }`}
    >
      <Link
        className={style.navbar_logoContainer}
        to={`${process.env.REACT_APP_WEBSITE_URL}/`}
        // onClick={() => {
        //   window.scrollTo({ top: 0, behavior: "smooth" });
        // }}
      >
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ LOGO"
          width={60}
          height={60}
        />
      </Link>
      <div className={style.navbar_InputFieldDropDown_Container}>
        <form
          className={`${
            inputFieldisActive
              ? style.navbar_inputFieldWrapper
              : style.navbar_inputFieldMobileWrapper
          } ${searchInputData?.length > 0 && style.input_field_full_opacity}`}
          ref={searchDropDownRef}
          onSubmit={onInputFormHandler}
        >
          <input
            type="text"
            placeholder={`${inputFieldisActive ? "Search" : ""}`}
            className={` ${
              inputFieldisActive
                ? style.inputFieldMobile_Container
                : style.inputField_Container
            }`}
            value={searchInputData || ""}
            ref={searchKeywordRef}
            onChange={(e) => setSearchInputData(e.target.value)}
          />
          <IoIosSearch
            className={`${style.inputfield_searchIcon} ${
              searchInputData?.length > 0 && style.search_icon_sky_color
            }`}
            onClick={onSearchBarExtendHandler}
          />
          {!searchInputData && (
            <div className={style.navbar_Scrolling_Wrapper}>
              <span>Search for </span>
              <div className={style.ScrollPlaceholder_Container}>
                <ul>
                  {randomData.map((item, key) => {
                    return <li key={key}>{`"${item}"`}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}

          {searchInputData && (
            <div
              className={`${
                searchSuggestionsData?.length
                  ? style.navbarDropdown_searchArticleList
                  : style.navbarDropdown_empty_searchArticleList
              }`}
            >
              <div className={style.navbarDropdown_SearchArticleBox}>
                <div
                  className={`${
                    searchSuggestionsData?.length >= 1
                      ? style.navbar_allSearchArticlesContainer
                      : style.navbar_empty_allSearchArticlesContainer
                  }`}
                >
                  {searchInputData &&
                    searchSuggestionsData?.slice(0, 6).map((item, i) => {
                      return (
                        <SearchListItems
                          key={i}
                          title={item.title}
                          img_src={item.image}
                          slug={item.slug}
                          articleFormat={item.article_format}
                        />
                      );
                    })}
                </div>
                {searchSuggestionsData?.length >= 1 && (
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/search?q=${searchInputData}`}
                  >
                    <h2
                      className={style.navbar_SearchMoreArticles}
                      // onClick={onInputFormHandler}
                    >
                      See more results
                    </h2>
                  </Link>
                )}
                {searchSuggestionsData?.length <= 0 && (
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/search?q=${searchInputData}`}
                  >
                    <h2
                      className={style.navbar_empty_SearchMoreArticles}
                      // onClick={onInputFormHandler}
                    >
                      See more results
                    </h2>
                  </Link>
                )}
              </div>
            </div>
          )}
        </form>
        {/* <div
          className={`${style.navbar_menuGrid_container} ${
            isHoverable
              ? style.navbar_display_hover
              : style.navbar_display_nonhover
          }`}
          onClick={onGridMenuHandler}
          ref={capabilityIconRef}
        >
          <CgMenuGridR
            className={`${style.menuGrid_icon} ${
              isCapabilitydropdownActive && style.menuGridIconBg
            }`}
          />
        </div> */}
      </div>
      {/* <div
        className={`${style.navbar_secureTranserIcon_Container} ${
          isHoverable
            ? style.navbar_display_hover
            : style.navbar_display_nonhover
        }`}
      >
        <Link href="https://access.ez.works/">
          <a aria-label="Secure Transfer">
            <IoPower className={style.securetransfer_icon} />
          </a>
        </Link>
      </div> */}
      {/* <>
        <ServiceDropLists
          setShowIconList={setIsCapabilitydropdownActive}
          showIconList={isCapabilitydropdownActive}
          refElement={capabilityDropdownRef}
        />
      </> */}
      <NavbarRouterBtns
        isCapabilitydropdownActive={isCapabilitydropdownActive}
        mobilecapabilityuIconRef={mobilecapabilityuIconRef}
        setIsCapabilitydropdownActive={setIsCapabilitydropdownActive}
        capabilityIconRef={capabilityIconRef}
        capabilityDropdownRef={capabilityDropdownRef}
      />
    </nav>
  );
};

export default Navbar;
