import React, { useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { searchPlugin } from "@react-pdf-viewer/search";
// import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "./PdfPreview.scss";
import "@react-pdf-viewer/core/lib/styles/index.css"; // for pdf styles
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { useSelector } from "react-redux";

const PdfPreview = () => {
  const { file_preview } = useSelector((state) => state.consultGptData);
  const [previewFileLoaded, setPreviewFileLoaded] = useState(false);

  // const searchPluginInstance = searchPlugin();
  // const pageNavigationInstance = pageNavigationPlugin();

  // const { jumpToPage } = pageNavigationInstance;

  // useEffect(() => {
  //   if (previewFileLoaded && file_preview?.reference_page) {
  //     console.log(file_preview?.reference_page, "reference_page");
  //     jumpToPage(file_preview?.reference_page);
  //     // highlight(file_preview.reference_text);
  //   }
  // }, [previewFileLoaded, file_preview?.reference_page]);

  const initialPage =
    file_preview?.reference_page !== undefined
      ? file_preview?.reference_page > 2
        ? file_preview?.reference_page - 1
        : file_preview?.reference_page
      : 0;

  return (
    <div className="rpv-core__viewer tools_consult_pdf_container">
      {!previewFileLoaded && (
        <div className="file_preview_downloading">Loading preveiw!</div>
      )}
      <div className="tools_consult_pdf_wrapper">
        {file_preview?.file_preview_url && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={file_preview?.file_preview_url}
              onDocumentLoad={() => {
                setPreviewFileLoaded(true);
              }}
              initialPage={initialPage}
            />
          </Worker>
        )}
      </div>
    </div>
  );
};

export default PdfPreview;
