import React, { useEffect, useState } from "react";
import { IoReloadCircleSharp } from "react-icons/io5";
import CustomFileInput from "./CustomFileInput/CustomFileInput";
import "./NewRequest.scss";
import useInputUpload from "./useInputUpload";
import toast from "react-hot-toast";
import { setSourceLoader } from "../../../store/utilsDataSlice/utilsDataSlice";
import { useDispatch, useSelector } from "react-redux";
const InputFiles = ({ setFormData, formData, setClear, clear }) => {
  const [
    filePath,
    setFilePaths,
    filesObj,
    setFilesObj,
    filesChunksGenerator,
    startFileUpload,
  ] = useInputUpload({
    tool_service: "assignment",
    chunk_generation_url: `${process.env.REACT_APP_API_URL}/api-client/tools/v1/upload-get/
        `,
    file_upload_complete_url: `${process.env.REACT_APP_API_URL}/api-client/tools/v1/mulipart-complete/`,
  });

  const { source_loader, reference_loader } = useSelector(
    (state) => state.utilsData,
  );
  const dispatch = useDispatch();
  const [sourceFile, setSourceFile] = useState(null);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    "Drag & drop files here or Click to Upload",
  );

  useEffect(() => {
    if (!uploadStarted && Object.keys(filesObj).length > 0) {
      handleFileUpload();
      setUploadStarted(true);
    }
  }, [filesObj, uploadStarted]);
  const handleReset = () => {
    if (!source_loader && !reference_loader) {
      setFormData({ ...formData, source_file: [], reference_file: [] });
      setFilePaths([]);
      setSourceFile([]);
      setClear(!clear);
    } else {
      toast.error("Previous File(s) are being uploaded please wait", {
        id: "resetError",
      });
    }
  };

  const maxCollectiveFileSizeFnErr = () => {
    toast.error("Source Files Collective size is greater than 5 Gb", {
      id: "max-source-collective",
    });
  };
  const handleFileUpload = async (e) => {
    // e.preventDefault();
    if (Object.values(filesObj).length === 0) return;

    // toast.success("Please wait till your files are being uploaded", {
    //   id: "new-req-upload",
    // });
    const fileObj = filesObj[Object.values(filesObj)[0].fileIdentifier];
    dispatch(setSourceLoader());
    await startFileUpload({ fileObj, filesObj });
  };

  const handleFileChange = (files) => {
    const filesObj = filesChunksGenerator(files);
    dispatch(setSourceLoader());
    setFilesObj(filesObj);
    setUploadStarted(false);
  };

  useEffect(() => {
    setFormData({ ...formData, source_file: filePath });
  }, [filePath]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 636) {
        setPlaceholder("Click to Upload");
      } else {
        setPlaceholder("Drag & drop files here or Click to Upload");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="st_input_files_container_main">
      <div className="st_input_files_container">
        <span className="st_input_files_heading">INPUT FILE(S)*</span>
        <span className="st_input_reset_icon">
          <IoReloadCircleSharp onClick={handleReset} color="var(--white)" />
        </span>
      </div>
      <CustomFileInput
        uploadStarted={uploadStarted}
        // handleFileUpload={handleFileUpload}
        formData={formData}
        maxCollectiveFileSizeFnErr={maxCollectiveFileSizeFnErr}
        files={sourceFile}
        maxFileSizeErrFn={maxCollectiveFileSizeFnErr}
        setFiles={setSourceFile}
        handleChange={handleFileChange}
        placeholder={placeholder}
        maxFileSize={5120}
        maxCollectiveFileSize={5120}
        setFilePaths={setFilePaths}
      />
    </div>
  );
};

export default InputFiles;
