import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState, { status_obj } from "./common";
import { getAuthData, postAuthData } from "../../helpers/request";
import TOOLS_PROGRESS from "../../ToolsModule/ToolsConstants/TOOLS_PROGRESS";
import { devEnvNew } from "../..";
import tools_details_data from "../../ToolsModule/ToolsJsonData/ToolsDetailsData.json";
import tools_file_list from "../.././ToolsModule/ToolsJsonData/ToolsFilesList.json";
import TOOLS_TYPE from "../../ToolsModule/ToolsConstants/TOOLS_TYPE";
import { FILE_ENCRYPTION_STATES } from "../../client_module/ClientComponents";
export const getToolsFileList = createAsyncThunk(
  "tools-data/getToolsFileList",

  async ({ tool_type, search_text = null }, thunkAPI) => {
    if (devEnvNew) {
      return { list: tools_file_list.data, search: search_text };
    } else {
      let url;
      if (tool_type === TOOLS_TYPE.CONSULT) {
        url = `${
          process.env.REACT_APP_CONSULT_URL
        }/api-client/tools/v1/chat-data/?file_name=${search_text || ""}`;
      } else {
        url = `${
          process.env.REACT_APP_API_URL
        }/api-client/tools/v1/get-assignments?tool=${tool_type}&file_name=${
          search_text || ""
        }`;
      }
      try {
        const res = await getAuthData(url);
        if (res.success) {
          return { list: res?.data, search: search_text };
        } else {
          return thunkAPI.rejectWithValue(
            res?.message || "Error while loading data!",
          );
        }
      } catch (err) {
        throw thunkAPI.rejectWithValue("Something went wrong!");
      }
    }
  },
);

export const getToolsFileDetails = createAsyncThunk(
  "tools-data/getToolsFileDetails",
  async ({ file_id }, thunkAPI) => {
    thunkAPI.dispatch(resetToolsDetailsData());
    if (devEnvNew) {
      return tools_details_data.data;
    } else {
      let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/get-assignments/${file_id}`;
      try {
        const res = await getAuthData(url);
        if (res.success) {
          return res?.data;
        } else {
          return thunkAPI.rejectWithValue(
            res?.message || "Error while loading data!",
          );
        }
      } catch (err) {
        throw thunkAPI.rejectWithValue("Something went wrong!");
      }
    }
  },
);

export const getToolsFileOutput = createAsyncThunk(
  "tools-data/getToolsFileOutput",
  async ({ tool_type, file_id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/${tool_type}/${file_id}/`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const getToolsFileOutputPreviewFile = createAsyncThunk(
  "tools-data/getToolsFileOutputPreviewFile",
  async ({ tool_type, file_id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/${tool_type}/${file_id}/`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const getSrcFilePdfFullLink = createAsyncThunk(
  "tools-data/getSrcFilePdfFullLink",
  async ({ file_id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/full-view/`;
    try {
      const res = await postAuthData(url, {
        id: file_id,
        is_guest: false,
        file_type: "source",
      });
      if (res.success) {
        return res?.data?.link;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const getTrgFilePdfFullLink = createAsyncThunk(
  "tools-data/getTrgFilePdfFullLink",
  async ({ file_id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/full-view/`;
    try {
      const res = await postAuthData(url, {
        id: file_id,
        is_guest: false,
        file_type: "target",
      });
      if (res.success) {
        return res?.data?.link;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const getToolsFileOutputStatus = createAsyncThunk(
  "tools-data/getToolsFileOutputStatus",
  async ({ tool_type, file_id, retry_flag = 0, new_data = null }, thunkAPI) => {
    let re_req_flag = false; // this flag avoids multiple api calls from different timeouts
    let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/${tool_type}/${file_id}`;
    if (file_id != thunkAPI.getState().toolsData.tools_details_data.data?.id) {
      return;
    }

    // getting src preview
    if (!thunkAPI.getState().toolsData.output_status.data.src_preview?.status) {
      url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/${tool_type}/${file_id}/?source=true`;
    }
    const TOOLS_TIMER = {
      flip: 6,
      read: 10,
      translate: 20,
      consult: 10,
    };
    // not to execute the recurring function if the file is not in download stage other wise the show more will not work
    if (
      thunkAPI.getState().toolsData.tools_details_data.data?.file_stage !==
        FILE_ENCRYPTION_STATES.DOWNLOAD &&
      thunkAPI.getState().toolsData.output_status.data.src_preview?.status
    )
      return;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        let data = JSON.parse(JSON.stringify(status_obj));
        if (new_data) {
          // updating the data with the existing data
          data = { ...new_data };
        }
        data.progress = res?.data?.progress;
        data.status = res?.data?.status;
        data.message = res?.data?.message;
        data.count = res?.data?.count;
        data.time_saved = res?.data?.time_saved;
        data.type_count = res?.data?.type_count;
        data.file_id = file_id;

        // data.src_preveiw_file_link = res?.data?.source_pdf_link; // FIX: Remove after update
        // data.trg_preveiw_file_link = res?.data?.target_pdf_link; // FIX: Remove after updte

        // for download links
        data.download_links =
          res?.data?.download_link?.length > 0
            ? res?.data?.download_link
            : data.download_links;

        // for preview we need to decide if the status is true or false in order to determine the res is a link or err
        //for src
        if (res?.data?.source_pdf_link?.status) {
          data.src_preview = {
            status: true,
            link: res?.data?.source_pdf_link?.res,
            err: null,
          };
        } else if (
          !res?.data?.source_pdf_link?.status &&
          !data.src_preview.status
        ) {
          data.src_preview = {
            status: false,
            link: null,
            err: res?.data?.source_pdf_link?.res,
          };
        }
        // for trg
        if (res?.data?.target_pdf_link?.status) {
          data.trg_preview = {
            status: true,
            link: res?.data?.target_pdf_link?.res,
            err: null,
          };
        } else if (
          !res?.data?.target_pdf_link?.status &&
          !data.trg_preview.status
        ) {
          data.trg_preview = {
            status: false,
            link: null,
            err: res?.data?.target_pdf_link?.res,
          };
        }

        if (data.status === TOOLS_PROGRESS.SUCCESS) {
          thunkAPI.dispatch(updateStatusState(data));
          // for target file
          if (
            !data.trg_preview.status &&
            !thunkAPI.getState().toolsData.output_status.data.trg_preview.status
          ) {
            if (retry_flag < 10) {
              re_req_flag = true;
              setTimeout(() => {
                thunkAPI.dispatch(
                  getToolsFileOutputStatus({
                    tool_type,
                    file_id,
                    retry_flag: retry_flag + 1,
                    new_data: data,
                  }),
                );
              }, 1000 * TOOLS_TIMER[tool_type]);
            } else {
              let err_msg =
                data.trg_preview?.err || "Failed to load preview file!";
              thunkAPI.dispatch(updateStatusState(data));
              throw thunkAPI.rejectWithValue(err_msg);
            }
          }
          // for source file
          if (
            !re_req_flag &&
            !data.src_preview.status &&
            !thunkAPI.getState().toolsData.output_status.data.src_preview.status
          ) {
            if (retry_flag < 10) {
              re_req_flag = true;
              setTimeout(() => {
                thunkAPI.dispatch(
                  getToolsFileOutputStatus({
                    tool_type,
                    file_id,
                    retry_flag: retry_flag + 1,
                    new_data: data,
                  }),
                );
              }, 1000 * TOOLS_TIMER[tool_type]);
            } else {
              let err_msg =
                data.src_preview?.err || "Failed to load preview file!";
              thunkAPI.dispatch(updateStatusState(data));
              throw thunkAPI.rejectWithValue(err_msg);
            }
          }
          // if (
          //   !data.trg_preveiw_file_link &&
          //   !thunkAPI.getState().toolsData.output_status.data
          //     .trg_preveiw_file_link
          // ) {
          //   if (retry_flag < 4) {
          //     setTimeout(() => {
          //       thunkAPI.dispatch(
          //         getToolsFileOutputStatus({
          //           tool_type,
          //           file_id,
          //           retry_flag: retry_flag + 1,
          //           new_data: data,
          //         }),
          //       );
          //     }, 1000 * TOOLS_TIMER[tool_type]);
          //   } else {
          //     let err_msg =
          //       data.trg_preveiw_file_link?.err ||
          //       "Failed to load preview file!";
          //     thunkAPI.dispatch(
          //       updateStatusState({
          //         ...data,
          //         trg_preveiw_file_link: { err: err_msg },
          //       }),
          //     );
          //     throw thunkAPI.rejectWithValue(err_msg);
          //   }
          // }
          // FIX: TO be removed once src file is found in target
          // if (
          //   !data.src_preveiw_file_link &&
          //   !thunkAPI.getState().toolsData.output_status.data
          //     .src_preveiw_file_link
          // ) {
          //   if (retry_flag < 4) {
          //     setTimeout(() => {
          //       thunkAPI.dispatch(
          //         getToolsFileOutputStatus({
          //           tool_type,
          //           file_id,
          //           retry_flag: retry_flag + 1,
          //           new_data: data,
          //         }),
          //       );
          //     }, 1000 * TOOLS_TIMER[tool_type]);
          //   } else {
          //     let err_msg =
          //       data.src_preveiw_file_link?.err ||
          //       "Failed to load preview file!";
          //     thunkAPI.dispatch(
          //       updateStatusState({
          //         ...data,
          //         src_preveiw_file_link: { err: err_msg },
          //       }),
          //     );
          //     throw thunkAPI.rejectWithValue(err_msg);
          //   }
          // }
        } else if (data.status === TOOLS_PROGRESS.IN_PROGRESS) {
          thunkAPI.dispatch(updateStatusState(data));
          re_req_flag = true;
          setTimeout(() => {
            thunkAPI.dispatch(
              getToolsFileOutputStatus({ tool_type, file_id, new_data: data }),
            );
          }, 1000 * TOOLS_TIMER[tool_type]);
        } else if (data.status === TOOLS_PROGRESS.FAILED) {
          thunkAPI.dispatch(updateStatusState(data));
          if (retry_flag < 0 && !re_req_flag) {
            re_req_flag = true;
            setTimeout(() => {
              thunkAPI.dispatch(
                getToolsFileOutputStatus({
                  tool_type,
                  file_id,
                  retry_flag: retry_flag + 1,
                  data: new_data,
                }),
              );
            }, 1000 * TOOLS_TIMER[tool_type]);
          } else {
            let err_msg = data?.message || "Failed to load download links!";
            data.trg_preview = {
              ...data.trg_preview,
              err: data?.message || "Can't load PDF",
            };
            data.download_links = {
              ...data.download_links,
              err: data?.message || "Error",
            };
            thunkAPI.dispatch(updateStatusState(data));
            throw thunkAPI.rejectWithValue(err_msg);
          }
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

const toolsDataSlice = createSlice({
  name: "tools-data",
  initialState,
  reducers: {
    setSearchText: (state, { payload }) => {
      state.tools_list_data.search_text = payload;
    },
    setSrcFullPreview: (state, { payload }) => {
      state.preview_full_data.src_pdf.full = payload;
    },
    setTrgFullPreview: (state, { payload }) => {
      state.preview_full_data.trg_pdf.full = payload;
    },
    setSrcFullPreviewLink: (state, { payload }) => {
      state.preview_full_data.src_pdf.link = payload;
    },
    setTrgFullPreviewLink: (state, { payload }) => {
      state.preview_full_data.trg_pdf.link = payload;
    },
    updateStatusState: (state, { payload }) => {
      if (!payload) return;
      if (payload.src_preview && !state.output_status.data.src_preview.status) {
        state.output_status.data.src_preview = payload.src_preview;
      }
      if (payload.trg_preview && !state.output_status.data.trg_preview.status) {
        state.output_status.data.trg_preview = payload.trg_preview;
      }
      // if (
      //   payload.src_preveiw_file_link &&
      //   !state.output_status.data.src_preveiw_file_link
      // ) {
      //   state.output_status.data.src_preveiw_file_link =
      //     payload.src_preveiw_file_link;
      // }
      // if (
      //   payload.trg_preveiw_file_link &&
      //   !state.output_status.data.trg_preveiw_file_link
      // ) {
      //   state.output_status.data.trg_preveiw_file_link =
      //     payload.trg_preveiw_file_link;
      // }
      if (payload.download_links) {
        state.output_status.data.download_links = payload.download_links;
      }
      if (payload.progress) {
        state.output_status.data.progress = payload.progress;
      }
      if (payload.status) {
        state.output_status.data.status = payload.status;
      }
      if (payload.time_saved) {
        state.tools_details_data.data.time_saved = payload.time_saved;
      }
      if (payload.count) {
        state.tools_details_data.data.count = payload.count;
        let arr_idx = state.tools_list_data.data?.findIndex(
          (item) => item.id == [payload.file_id],
        );
        state.tools_list_data.data[arr_idx].count = payload.count;
      }
      if (payload.type_count) {
        state.tools_details_data.data.count = payload.type_count;
        let arr_idx = state.tools_list_data.data?.findIndex(
          (item) => item.id == [payload.file_id],
        );
        state.tools_list_data.data[arr_idx].type_count = payload.type_count;
      }
    },
    resetToolsDetailsData: (state) => {
      state.tools_details_data.data = initialState.tools_details_data.data;
      state.preview_full_data = initialState.preview_full_data;
    },
    resetPreviewOutput: (state) => {
      state.output_preview = initialState.output_preview;
    },
    resetOutputStatus: (state) => {
      state.output_status.data = initialState.output_status.data;
    },

    // updateChatQueryCount: (state, { payload }) => {
    //   console.log(payload, "tools_list_data");
    //   // state.tools_list_data
    // },
    updateChatLabel: (state, { payload }) => {
      state.tools_list_data.data = [payload, ...state.tools_list_data.data];
    },
    updateSidebarQueryCount: (state, { payload }) => {
      const { type_count, id, file_name } = payload;
      const updatedData = state.tools_list_data.data.map((item) => {
        if (item?.id === id) {
          const data = { ...item };
          if (file_name !== undefined) data["file_name"] = file_name;
          if (type_count !== undefined) data["count"] = type_count;

          return data;
        }
        return item;
      });

      return {
        ...state,
        tools_list_data: {
          ...state.tools_list_data,
          data: updatedData,
        },
      };
    },
  },

  extraReducers: (builder) => {
    // list
    builder.addCase(getToolsFileList.pending, (state) => {
      state.tools_list_data.loading = true;
    });
    builder.addCase(getToolsFileList.fulfilled, (state, { payload }) => {
      state.tools_list_data.loading = false;
      state.tools_list_data.data = payload?.list;
      state.tools_list_data.search_text = payload?.search;
    });
    builder.addCase(getToolsFileList.rejected, (state, { payload }) => {
      state.tools_list_data.loading = false;
      state.tools_list_data.error = payload;
      state.tools_list_data.data = null;
    });
    // Details
    builder.addCase(getToolsFileDetails.pending, (state) => {
      state.tools_details_data.loading = true;
    });
    builder.addCase(getToolsFileDetails.fulfilled, (state, { payload }) => {
      state.tools_details_data.loading = false;
      state.tools_details_data.data = payload;
    });
    builder.addCase(getToolsFileDetails.rejected, (state, { payload }) => {
      state.tools_details_data.loading = false;
      state.tools_details_data.error = payload;
    });
    // output
    // builder.addCase(getToolsFileOutput.pending, (state) => {
    //   state.output_details.loading = true;
    // });
    // builder.addCase(getToolsFileOutput.fulfilled, (state, { payload }) => {
    //   state.output_details.loading = false;
    //   state.output_details.data = payload;
    // });
    // builder.addCase(getToolsFileOutput.rejected, (state, { payload }) => {
    //   state.output_details.loading = false;
    //   state.output_details.error = payload;
    // });
    // // output preview file
    // builder.addCase(getToolsFileOutputPreviewFile.pending, (state) => {
    //   state.output_preview.loading = true;
    // });
    // builder.addCase(
    //   getToolsFileOutputPreviewFile.fulfilled,
    //   (state, { payload }) => {
    //     state.output_preview.loading = false;
    //     state.output_preview.data = payload;
    //   },
    // );
    // builder.addCase(
    //   getToolsFileOutputPreviewFile.rejected,
    //   (state, { payload }) => {
    //     state.output_preview.loading = false;
    //     state.output_preview.error = payload;
    //   },
    // );
    // src full pdf
    builder.addCase(getSrcFilePdfFullLink.pending, (state) => {
      state.preview_full_data.src_pdf.loading = true;
    });
    builder.addCase(getSrcFilePdfFullLink.fulfilled, (state, { payload }) => {
      state.preview_full_data.src_pdf.loading = false;
      state.preview_full_data.src_pdf.link = payload;
    });
    builder.addCase(getSrcFilePdfFullLink.rejected, (state, { payload }) => {
      state.preview_full_data.src_pdf.loading = false;
      state.preview_full_data.src_pdf.error = payload;
    });
    // trg full pdf
    builder.addCase(getTrgFilePdfFullLink.pending, (state) => {
      state.preview_full_data.trg_pdf.loading = true;
    });
    builder.addCase(getTrgFilePdfFullLink.fulfilled, (state, { payload }) => {
      state.preview_full_data.trg_pdf.loading = false;
      state.preview_full_data.trg_pdf.link = payload;
    });
    builder.addCase(getTrgFilePdfFullLink.rejected, (state, { payload }) => {
      state.preview_full_data.trg_pdf.loading = false;
      state.preview_full_data.trg_pdf.error = payload;
    });
    // output status
    builder.addCase(getToolsFileOutputStatus.pending, (state) => {
      state.output_status.loading = true;
    });
    builder.addCase(
      getToolsFileOutputStatus.fulfilled,
      (state, { payload }) => {
        if (!payload) return;
        state.output_status.loading = false;
        if (
          payload.src_preview &&
          !state.output_status.data.src_preview.status
        ) {
          state.output_status.data.src_preview = payload.src_preview;
        }
        if (
          payload.trg_preview &&
          !state.output_status.data.trg_preview.status
        ) {
          state.output_status.data.trg_preview = payload.trg_preview;
        }
        // if (
        //   payload.src_preveiw_file_link &&
        //   !state.output_status.data.src_preveiw_file_link
        // ) {
        //   state.output_status.data.src_preveiw_file_link =
        //     payload.src_preveiw_file_link;
        // }
        // if (
        //   payload.trg_preveiw_file_link &&
        //   !state.output_status.data.trg_preveiw_file_link
        // ) {
        //   state.output_status.data.trg_preveiw_file_link =
        //     payload.trg_preveiw_file_link;
        // }
        if (payload.download_links) {
          state.output_status.data.download_links = payload.download_links;
        }
        if (payload.progress) {
          state.output_status.data.progress = payload.progress;
        }
        if (payload.status) {
          state.output_status.data.status = payload.status;
        }
        if (payload.time_saved) {
          state.tools_details_data.data.time_saved = payload.time_saved;
        }
        if (payload.count) {
          state.tools_details_data.data.count = payload.count;
          let arr_idx = state.tools_list_data.data?.findIndex(
            (item) => item.id == [payload.file_id],
          );
          state.tools_list_data.data[arr_idx].count = payload.count;
        }
        if (payload.type_count) {
          state.tools_details_data.data.type_count = payload.type_count;
          let arr_idx = state.tools_list_data.data?.findIndex(
            (item) => item.id == [payload.file_id],
          );
          state.tools_list_data.data[arr_idx].type_count = payload.type_count;
        }
      },
    );
    builder.addCase(getToolsFileOutputStatus.rejected, (state, { payload }) => {
      state.output_status.loading = false;
      state.output_status.error = payload;
    });
  },
});

export const {
  setSearchText,
  setSrcFullPreview,
  setTrgFullPreview,
  setSrcFullPreviewLink,
  setTrgFullPreviewLink,
  updateStatusState,
  resetToolsDetailsData,
  resetPreviewOutput,
  resetOutputStatus,
  updateChatLabel,
  updateSidebarQueryCount,
} = toolsDataSlice.actions;

export default toolsDataSlice.reducer;
