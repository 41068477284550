import React, { useEffect, useState } from "react";
import "./NewRequest.scss";
import useReferenceUpload from "./useReferenceUpload";
import ReferenceFileInpput from "./CustomFileInput/ReferenceFileInput";
import toast from "react-hot-toast";
const ReferenceFiles = ({ setFormData, formData, clear }) => {
  const [
    filePaths,
    setFilePaths,
    filesObj,
    setFilesObj,
    filesChunksGenerator,
    startFileUpload,
  ] = useReferenceUpload({
    // setIsSubmitting,
    tool_service: "assignment",
    chunk_generation_url: `${process.env.REACT_APP_API_URL}/api-client/tools/v1/upload-get/
        `,
    file_upload_complete_url: `${process.env.REACT_APP_API_URL}/api-client/tools/v1/mulipart-complete/`,
  });
  const [uploadStarted, setUploadStarted] = useState(false);
  const [referenceFile, setReferenceFile] = useState(null);
  const [placeholder, setPlaceholder] = useState(
    "Drag & drop files here or Click to Upload",
  );

  const maxCollectiveFileSizeFnErr = () => {
    toast.error("Reference Files Collective size is greater than 5 Gb", {
      id: "max-collective",
    });
  };
  useEffect(() => {
    if (!uploadStarted && Object.keys(filesObj).length > 0) {
      handleFileUpload();
      setUploadStarted(true);
    }
  }, [filesObj, uploadStarted]);

  const handleFileUpload = async (e) => {
    if (Object.values(filesObj).length === 0) return;
    // toast.success("Please wait till your files are being uploaded", {
    //   id: "new-req-upload",
    // });
    const fileObj = filesObj[Object.values(filesObj)[0].fileIdentifier];
    await startFileUpload({ fileObj, filesObj });
  };
  const handleFileChange = (files) => {
    const filesObj = filesChunksGenerator(files);
    setFilesObj(filesObj);
    setUploadStarted(false);
  };

  useEffect(() => {
    setFormData({ ...formData, reference_file: filePaths });
  }, [filePaths]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 636) {
        setPlaceholder("Click to Upload");
      } else {
        setPlaceholder("Drag & drop files here or Click to Upload");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="st_input_files_container_main">
      <div className="st_input_files_container">
        <span className="st_input_files_heading">REFERENCE FILE(S)</span>
      </div>
      <ReferenceFileInpput
        clear={clear}
        maxFileSizeErrFn={maxCollectiveFileSizeFnErr}
        maxCollectiveFileSizeFnErr={maxCollectiveFileSizeFnErr}
        uploadStarted={uploadStarted}
        formData={formData}
        file={referenceFile}
        setFile={setReferenceFile}
        handleChange={handleFileChange}
        placeholder={placeholder}
        maxFileSize={5120}
        maxCollectiveFileSize={5120}
        setFilePaths={setFilePaths}
      />
    </div>
  );
};

export default ReferenceFiles;
